
<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("navBar", {
      refreshUrl: (state) => state.refreshUrl,
    }),
  },
  async mounted() {
    // setTimeout(() => {
    //   this.$router.replace({ path: this.refreshUrl });
    // }, 0)

    await this.$nextTick()
    this.$router.replace({ path: this.refreshUrl });
  },
};
</script>

<style>
</style>
